import React from "react";
import { get } from "lodash";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { collectBreadcrum } from "../utils";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const ContactIntro = loadable(() => import("../components/ContactIntro/ContactIntro"));
const ContactDetail = loadable(() => import("../components/ContactDetail/ContactDetail"));
const Forms = loadable(() => import("../components/Forms"));

const FormsComp = (props) => {

    const pageData = props.pageContext.data;
    const formData = get(props, 'data.wpGfForm', {});
    const formId = get(pageData, 'ACF_Modules.formId', false);

    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule data={collectBreadcrum(pageData)}/>

            <div className="contact-page-wrapper section-p">
                <Container>
                    <Row>
                        <Col xl={4}>
                            <ContactIntro 
                                tag="contact"
                                data={pageData}
                            />

                            <ContactDetail data={pageData} />
                        </Col>
                        <Col xl={1}></Col>
                        <Col xl={7}>
                          <Forms pageData={pageData} formId={formId} formData={formData}/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}


export const query = graphql`
  query ($formId: Int) {
    wpGfForm(databaseId: {eq: $formId}) {
    id
    title
    databaseId
    formFields {
      nodes {
        ... on WpTextField {
          databaseId
          cssClass
          errorMessage
          isRequired
          placeholder
          type
          label
        }
        ... on WpEmailField {
          databaseId
          cssClass
          errorMessage
          isRequired
          placeholder
          type
          label
        }
        ... on WpPhoneField {
          databaseId
          cssClass
          errorMessage
          isRequired
          placeholder
          type
          label
        }
        ... on WpTextAreaField {
          databaseId
          cssClass
          errorMessage
          isRequired
          placeholder
          type
          label
        }
        ... on WpSelectField {
          databaseId
          cssClass
          errorMessage
          isRequired
          placeholder
          type
          label
          choices {
            text
            value
          }
        }
        ... on WpDateField {
          databaseId
          cssClass
          errorMessage
          isRequired
          placeholder
          type
          label
          dateFormat
        }
        ... on WpTimeField {
          databaseId
          cssClass
          errorMessage
          isRequired
          type
          label
          timeFormat
        }
        ... on WpFileUploadField {
          databaseId
          cssClass
          errorMessage
          isRequired
          type
          label
          allowedExtensions
          canAcceptMultipleFiles
        }
      }
    }
  }
  }  
`


export default FormsComp
